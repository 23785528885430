import dayjs from 'dayjs'
import { FC, Key, useCallback, useMemo } from 'react'
import { snapshot_UNSTABLE } from 'recoil'
import { toTimeZoneString } from '../../helpers/date'
import { createBooleanDataset } from '../../helpers/others'
import { Config, configState } from '../../store/config'
import { gymState } from '../../store/gym'
import { Button } from '../Button'
import { Tag } from '../Tag'
import s from './MarketClasses.module.css'

const MarketClasses: FC<MarketClassesProps> = (props) => {
  const { items, selectedItems, onClickItem } = props

  const createHandleItemSelect = useCallback(
    (key: MarketClassItem['key']) => () => {
      const item = items.find((item) => item.key === key)

      if (getDisabled(item)) {
        return
      }

      onClickItem?.(key)
    },
    [items, onClickItem]
  )

  const itemElements = useMemo(() => {
    return items.map((item) => {
      const full = item.stock <= 0
      const disabled = getDisabled(item)
      const selected = selectedItems?.has(item.key)

      return (
        <li
          key={item.key}
          className={s.item}
          {...createBooleanDataset({
            booked: item.booked,
            selected: selected,
            disabled: disabled,
          })}
          onClick={createHandleItemSelect(item.key)}
        >
          <div className={s.base}>
            <div className={s.cover}>
              <img
                src={item.staff.avatar}
                alt={item.staff.name}
                className={s.avatar}
              />
              <div>
                <div className={s.title}>
                  <span className={s.name}>{item.name}</span>
                  <Tag casing="upper">{item.level}</Tag>
                </div>
                <div className={s['staff']}>with {item.staff.name}</div>
              </div>
            </div>
            <div className={s.description}>{item.description}</div>
          </div>
          <div className={s.actions}>
            <div className={s.time}>
              {dayjs(item.startTime).format('ddd, MMMM DD')}
              <br />
              {dayjs(item.startTime).format('h:mm a')} -{' '}
              {dayjs(item.endTime).format('h:mm a')}
            </div>
            <div className={s.stock}>
              {full ? 'Full, book for waitlist' : `${item.stock} spots free`}
            </div>
            {disabled ? (
              <Button
                variant="ghost"
                color="black"
                disabled
                className={s.button}
              >
                {disabled}
              </Button>
            ) : (
              <Button className={s.button}>
                {selected ? 'unselect' : full ? 'waitlist' : 'select'}
              </Button>
            )}
          </div>
        </li>
      )
    })
  }, [createHandleItemSelect, items, selectedItems])

  return <ul className={s.root}>{itemElements}</ul>
}

const mapMarketClasses = (sessions: Dto.Session[] = []): MarketClassItem[] =>
  sessions
    .map((session) => {
      const config = snapshot_UNSTABLE().getLoadable(configState)
        .contents as Config
      const gym = snapshot_UNSTABLE().getLoadable(gymState).contents as Dto.Gym
      return {
        key: session.id,
        name: session.category.name,
        staff: {
          name: session.staff.first_name,
          avatar:
            session.staff.profile_picture ||
            gym.gym_logo ||
            `https://via.placeholder.com/200x200/${config.themeColor.slice(
              1
            )}/black?text=${`${session.staff.first_name.slice(
              0,
              1
            )}${session.staff.last_name.slice(0, 1)}`.toUpperCase()}`,
        },
        level: session.training_type,
        description: session.description || undefined,
        startTime: toTimeZoneString(session.session_start_time_utc),
        endTime: toTimeZoneString(session.session_end_time_utc),
        stock: session.member_limit - session.bookings,
        members: 99,
        booked: session.has_booked,
      }
    })
    .sort(
      (classA, classB) =>
        dayjs(classA.startTime).valueOf() - dayjs(classB.startTime).valueOf()
    )

const getDisabled = (item?: MarketClassItem) => {
  return (
    item &&
    (dayjs(item.startTime) < dayjs()
      ? 'completed'
      : item.booked
      ? 'booked'
      : false)
  )
}

type MarketClassesProps = {
  items: MarketClassItem[]
  selectedItems?: Set<MarketClassItem['key']>
  onClickItem?: (key: MarketClassItem['key']) => void
}

type MarketClassItem = {
  key: Key
  name: string
  staff: {
    name: string
    avatar: string
  }
  level: string
  description?: string
  startTime: string
  endTime: string
  stock: number
  booked?: boolean
}

export { MarketClasses, mapMarketClasses }
export type { MarketClassesProps, MarketClassItem }
