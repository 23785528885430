import { useCallback, useRef, useState } from 'react'
import {
  SignModals,
  SignModalsContext,
  SignModalsContextValue,
} from '../components/SignModals'
import { useSwitch } from './switch'

const useSign = () => {
  const { on, open, close } = useSwitch()

  const [context, setContext] = useState<SignModalsContextValue>()
  const resolveRef = useRef<Function>()

  const start = useCallback(
    (newContext?: SignModalsContextValue) => {
      return new Promise<never>((resolve) => {
        resolveRef.current = resolve
        setContext(newContext)
        open()
      })
    },
    [open]
  )

  const handleFinish = useCallback(() => {
    close()
    resolveRef.current?.()
  }, [close])

  const element = on ? (
    <SignModalsContext.Provider value={context || {}}>
      <SignModals
        defaultType="sign-in"
        onFinish={handleFinish}
        onCancel={close}
      />
    </SignModalsContext.Provider>
  ) : null

  return {
    element,
    start,
  }
}

export { useSign }
