/* eslint-disable jsx-a11y/alt-text */
import { FC, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import toast from 'react-hot-toast'
import { Button } from '../../components/Button'
import { Footer } from '../../components/Footer'
import { Form, FormProps } from '../../components/Form'
import {
  Goods,
  GoodsItem,
  mapPacks,
  mapPlans,
  mapProducts,
} from '../../components/Goods'
import { Header } from '../../components/Header'
import { PromoCodeField } from '../../components/PromoCodeField'
import { useGet } from '../../hooks/get'
import { usePurchase } from '../../hooks/purchase'
import { useSign } from '../../hooks/sign'
import { useUser } from '../../hooks/user'
import s from './Pricing.module.css'

const Pricing: FC = () => {
  const { data: plans } = useGet<Dto.Plan[]>(`member_portal/plans`)
  const { data: packs } = useGet<Dto.Pack[]>(`member_portal/packs`)
  const { data: products } = useGet<Dto.Product[]>(`member_portal/products`)

  const { user } = useUser()

  const [selectedItem, setSelectedItem] = useState<GoodsItem>()

  const sign = useSign()
  const purchase = usePurchase(selectedItem?.dto)

  const handleSubmit: FormProps['onSubmit'] = async (fields) => {
    if (!selectedItem) {
      toast.error('Please select an item first.')
      return
    }

    const promoCode = fields['promo-code']

    if (!user) {
      await sign.start({
        purchase: {
          item: selectedItem.dto,
          promoCode,
        },
      })
      return
    }

    try {
      await purchase.submit({ promo_code: promoCode })
      toast.success('Purchase succeeded.')
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message)
      }
    }
  }

  const groups = useMemo(() => {
    if (!(plans && packs)) {
      return
    }

    const Trials = mapPlans(
      plans.filter(
        (plan) => plan.version && plan.details?.payment.payment_schedule === 5
      )
    )
    const Packs = [
      ...mapPacks(packs || []),
      ...mapPlans(
        plans.filter(
          (plan) => plan.version && plan.details?.payment.payment_schedule === 4
        )
      ),
    ]

    const isSortedTo = (items: GoodsItem[], plan: Dto.Plan) =>
      items.find((item) => item.dto === plan)

    const Plans = mapPlans(
      plans.filter(
        (plan) => !isSortedTo(Trials, plan) && !isSortedTo(Packs, plan)
      )
    )

    return {
      Trials,
      Plans,
      Packs,
      Products: mapProducts(products || []),
    }
  }, [packs, plans, products])

  return (
    <>
      <Helmet>
        <title>Pricing</title>
      </Helmet>
      <div className={s.root}>
        <Header />
        <div className={s.goods}>
          <Goods items={groups || []} large onSelect={setSelectedItem} />
        </div>
        <div className={s['form-wrap']}>
          <Form onSubmit={handleSubmit}>
            <div className={s.form}>
              <PromoCodeField />
              {purchase.tcElement}
              <Button
                type="submit"
                block
                pending={purchase.pending}
                className={s.pay}
              >
                Pay
              </Button>
            </div>
          </Form>
        </div>
        <Footer />
      </div>
      {sign.element}
      {purchase.paymentMethodElement}
    </>
  )
}

export { Pricing }
