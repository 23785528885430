import { createContext, FC, useCallback, useState } from 'react'
import { GoodsItem } from '../Goods'
import { SignInModal, SignInModalProps } from './SignInModal'
import { SignUpModal, SignUpModalProps } from './SignUpModal'

const SignModals: FC<SignModalsProps> = (props) => {
  const { defaultType, ...passingProps } = props

  const [type, setType] = useState(defaultType)

  const handleRequestSignUp = useCallback(() => {
    setType('sign-up')
  }, [])

  return type === 'sign-in' ? (
    <SignInModal onRequestSignUp={handleRequestSignUp} {...passingProps} />
  ) : type === 'sign-up' ? (
    <SignUpModal {...passingProps} />
  ) : null
}

const SignModalsContext = createContext<SignModalsContextValue>({})

type SignModalsProps = Pick<
  SignInModalProps | SignUpModalProps,
  'onFinish' | 'onCancel'
> & {
  defaultType: 'sign-in' | 'sign-up'
}

type SignModalsContextValue = {
  categoryIds?: Dto.Session['category']['id'][]
  purchase?: { item: GoodsItem['dto']; promoCode?: string }
}

export { SignModals, SignModalsContext }
export type { SignModalsProps, SignModalsContextValue }
