import { Checkbox } from '../components/Checkbox'
import { Field } from '../components/Field'
import { GoodsItem, isProduct } from '../components/Goods'
import { Link } from '../components/Link'
import { Modal } from '../components/Modal'
import { Tc } from '../components/Tc'
import { useGet } from './get'
import { useSwitch } from './switch'

const useTc = (item?: GoodsItem['dto'], checkout?: Dto.Checkout) => {
  const { data: formTc } = useGet<Dto.Tc>(() => {
    if (!item || isProduct(item)) {
      return
    }

    const formId = item.term_form_id

    if (!formId) {
      return
    }

    return `member_portal/form/${formId}`
  })

  const formTcSwitch = useSwitch()
  const paymentTcSwitch = useSwitch()

  const gymTc = checkout?.terms?.gym
  const paymentTc = checkout?.terms?.payment

  const tcElement = formTc ? (
    <>
      <Field
        control={
          <Checkbox value={formTc.id} required>
            I've read and accepted{' '}
            <Link onClick={formTcSwitch.open}>Terms & Conditions</Link>
          </Checkbox>
        }
      />
      <Modal opened={formTcSwitch.on} onRequestClose={formTcSwitch.close}>
        <Tc data={formTc} />
      </Modal>
    </>
  ) : gymTc ? (
    <Field
      control={
        <Checkbox value={gymTc.id} required>
          I've read and accepted{' '}
          <Link href={gymTc.url} target="_blank">
            Terms & Conditions
          </Link>
        </Checkbox>
      }
    />
  ) : null
  const paymentTcElement = paymentTc && (
    <Field
      control={
        <Checkbox value={paymentTc.name} required>
          I've read and accepted{' '}
          {paymentTc.url ? (
            <Link href={paymentTc.url} target="_blank">
              Payment Agreement
            </Link>
          ) : (
            <>
              <Link onClick={paymentTcSwitch.open}>Payment Agreement</Link>
              <Modal
                opened={paymentTcSwitch.on}
                onRequestClose={paymentTcSwitch.close}
              >
                <Tc data={paymentTc} />
              </Modal>
            </>
          )}
        </Checkbox>
      }
    />
  )

  return {
    tcElement,
    paymentTcElement,
    formTc,
    gymTc,
  }
}

export { useTc }
