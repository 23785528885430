/* eslint-disable jsx-a11y/alt-text */
import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { Button } from '../../components/Button'
import { Footer } from '../../components/Footer'
import { Form, FormProps } from '../../components/Form'
import {
  Goods,
  isPack,
  isPlan,
  mapPacks,
  mapPlans,
  mapProducts,
} from '../../components/Goods'
import { Modal } from '../../components/Modal'
import { PromoCodeField } from '../../components/PromoCodeField'
import { useGet } from '../../hooks/get'
import { usePurchase } from '../../hooks/purchase'
import { useSign } from '../../hooks/sign'
import { useUser } from '../../hooks/user'
import s from './Purchase.module.css'

const Purchase: FC = () => {
  const params = useParams()

  const { user } = useUser()
  const sign = useSign()

  const { data: item } = useGet<Dto.Plan | Dto.Pack | Dto.Product>(
    user && `member_portal/${params.type}s/${params.id}`
  )

  const purchase = usePurchase(item)

  const [result, setResult] = useState<string>()

  const handleSubmit: FormProps['onSubmit'] = async (fields) => {
    if (!loaded) {
      return
    }

    try {
      await purchase.submit({ promo_code: fields['promo-code'] })
      setResult('Purchase succeeded. You can close this page safely.')
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message)
      }
    }
  }

  const loaded = !!(user && item)

  useEffect(() => {
    if (!user) {
      sign.start()
    }
  }, [sign, user])

  useEffect(() => {
    if (!isPlan(item) || item.latest_version_id === item.id) {
      return
    }

    window.location.replace(`./${item.latest_version_id}`)
  }, [item])

  return (
    <>
      <Helmet>
        <title>Purchase a {params.type}</title>
      </Helmet>
      {loaded && (
        <div className={s.root}>
          <img
            {...('image_url' in item && item.image_url
              ? {
                  src: item.image_url,
                  alt: item.name,
                }
              : {
                  src: item.gym!.gym_logo,
                  alt: item.gym!.name,
                })}
            className={s.logo}
          />
          <Goods
            items={
              isPlan(item)
                ? mapPlans([item])
                : isPack(item)
                ? mapPacks([item])
                : mapProducts([item])
            }
            defaultSelected={item.id}
            large
          />
          <Form onSubmit={handleSubmit}>
            <div className={s.form}>
              <PromoCodeField />
              {purchase.tcElement}
              <Button
                type="submit"
                block
                pending={purchase.pending}
                className={s.pay}
              >
                Pay
              </Button>
            </div>
          </Form>
          <Footer />
        </div>
      )}
      {sign.element}
      <Modal opened={!!result}>{result}</Modal>
      {purchase.paymentMethodElement}
    </>
  )
}

export { Purchase }
